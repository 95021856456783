<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <router-link v-if="$can('generate_voucher') " :to="{name: 'Add Voucher'}" class="btn btn-primary"><i class="fa fa-plus"></i> Generate</router-link>&nbsp;
          <b-dropdown id="dropdown-dropright" dropright text="Export" variant="success" class="m-2" v-if="$can('export-voucher')">
            <b-dropdown-item @click="exportTable">Export All</b-dropdown-item>
            <b-dropdown-item @click="exportMultiple">Export Voucher IDR Multiple Redeem</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
            description="Searchable : Code, Name"
          >
              <b-input-group
              >
                <b-form-input
                  id="search"
                  type="search"
                  v-model="filterText"
                  placeholder="Code, Name ..."
                  @keyup.enter="doFilter"
                >
                </b-form-input>
                <b-input-group-append
                >
                  <b-button
                    variant="secondary" @click="resetFilter"
                    type="button"
                  >
                    Reset
                  </b-button>
                </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Date :"
            label-for="daterange"
          >
            <b-input-group
            >
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker id="daterange" :start="startDate" :end="endDate" @picker="doDateFilter"></range-picker>
              <b-input-group-append>
                <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData">

      <template slot="code-slot" slot-scope="prop">
        <b-badge pill variant="dark" class="badge--code">{{ prop.rowData.code }}</b-badge>
      </template>
      <template slot="name-slot" slot-scope="prop">
        <span>{{ prop.rowData.name }}</span>
      </template>
      <template slot="start_from-slot" slot-scope="prop">
        <span :style="[timeNow >= prop.rowData.end_to ? {'color' : 'red'} : {'color' : 'green'}]"
          >{{ prop.rowData.start_from }}</span>
      </template>
      <template slot="end_to-slot" slot-scope="prop">
        <span :style="[timeNow >= prop.rowData.end_to ? {'color' : 'red'} : {'color' : 'green'}]"
          >{{ prop.rowData.end_to }}</span>
      </template>
      <template slot="program_start-slot" slot-scope="prop">
        <span :style="[timeNow >= prop.rowData.program_end ? {'color' : 'red'} : {'color' : 'green'}]"
          >{{ prop.rowData.program_start }}</span>
      </template>
      <template slot="program_end-slot" slot-scope="prop">
        <span :style="[timeNow >= prop.rowData.program_end ? {'color' : 'red'} : {'color' : 'green'}]"
          >{{ prop.rowData.program_end }}</span>
      </template>
      <template slot="amount-slot" slot-scope="prop">
        <span v-if="prop.rowData.voucher_type === 'gold'">
          {{ prop.rowData.amount }} Gram
        </span>
        <span v-else>
          {{ prop.rowData.amount | currency }}
        </span>
      </template>
      <template slot="actions-slot" slot-scope="prop">
        <div class="custom-actions">
          <button v-if="$can('detail_voucher')" class="btn btn-info" @click="onAction('detail-item', prop.rowData)" >Detail</button>&nbsp;
          <b-img v-show="prop.rowData.qrcode_path !== null " rounded alt="Rounded image" :src="require('../../../assets/img/qrcode.png')" width="30px" height="30px;" @click="onAction('download-item', prop.rowData)"></b-img>&nbsp;
          <button v-if="$can('delete_voucher')" class="btn btn-danger" @click="onAction('delete-item', prop.rowData)"><i class="fa fa-trash-o"></i></button>
        </div>
      </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"
      ></vuetable-pagination-info>

      <vuetable-pagination ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
  import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
  import moment from 'moment/src/moment'
  let startDate = '';
  let endDate = '';
  Vue.use(Vuetable)

  export default {
    components: {
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    data() {
      return {
        startDate: '',
        endDate: '',
        isLoading: false,
        filterText: '',
        dateDetail: {
          start_from: '',
          end_from: ''
        },
        timeNow: moment().format('YYYY-MM-DD hh:mm:ss'),
        errors: {
          code: '',
          message: '',
          status: ''
        },
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `vouchers`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sortOrder: [
          {
            field: 'created_at',
            sortField: 'created_at',
            direction: 'desc'
          }
        ],
        moreParams: {},
        fields: [
          {
            name: 'code-slot',
            sortField: 'code',
            title: 'Voucher Code'
          },
          {
            name: 'name-slot',
            sortField: 'name',
            title: 'Voucher Name'
          },
          {
            name: 'start_from-slot',
            sortField: 'start_from',
            title: 'Voucher Expired Start'
          },
          {
            name: 'end_to-slot',
            sortField: 'end_to',
            title: 'Voucher Expired End',
          },
          {
            name: 'program_start-slot',
            sortField: 'program_start',
            title: 'Program Start'
          },
          {
            name: 'program_end-slot',
            sortField: 'program_end',
            title: 'Program End'
          },
          {
            name: 'amount-slot',
            sortField: 'amount',
            titleClass: 'center aligned',
            dataClass: 'right aligned',
            title: 'Voucher Amount',
          },
          {
            name: 'available_status',
            titleClass: 'center aligned',
            dataClass: 'center aligned',
            title: 'Available',
            formatter: this.activeLabel
          },
          // {
          //   name: 'is_buyed',
          //   titleClass: 'center aligned',
          //   dataClass: 'center aligned',
          //   title: 'Is Bought',
          //   callback: 'boughtLabel'
          // },
          {
            name: 'actions-slot',
            title: 'Actions',
            titleClass: 'center aligned',
            dataClass: 'center aligned'
          }
        ]
      }
    },
    methods: {
      doFilter () {
        this.$events.$emit('filter-set', this.filterText)
      },
      resetFilter () {
        this.filterText = ''  // clear the text in text input
        this.$events.$emit('filter-reset')
      },
      exportTable() {
        this.isLoading = true
        if (this.startDate != '' && this.endDate != '') {
          startDate = this.startDate;
          endDate = this.endDate;
        }else{
          endDate = this.$setDate.format('YYYY-MM-DD');
          startDate = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
        }

        this.$http.get(`voucher/` + startDate + `/` + endDate)
        .then((result) => {
          this.isLoading = false
          const  exportPath = result.data.meta.data;
          window.location.href = exportPath;
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      },
      exportMultiple() {
        this.isLoading = true
        if (this.startDate != '' && this.endDate != '') {
          startDate = this.startDate;
          endDate = this.endDate;
        }else{
          endDate = this.$setDate.format('YYYY-MM-DD');
          startDate = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
        }

        this.$http.get(`voucher-serveral/` + startDate + `/` + endDate)
        .then((result) => {
          this.isLoading = false
          const  exportPath = result.data.meta.data;
          window.location.href = exportPath;
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      activeLabel (value) {
        if (value == true) {
          return '<span class="ui green label"><i class="fa fa-check"></i></span>'
        }else{
          return '<span class="ui red label"><i class="fa fa-times"></i></span>'
        }
      },
      boughtLabel (value) {
        if (value == true) {
          return '<span class="ui green label"><i class="fa fa-check"></i></span>'
        }else{
          return '<span class="ui red label"><i class="fa fa-times"></i></span>'
        }
      },
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        this.$refs.pagination.setPaginationData(paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
      },
      onAction (action, data) {
        if (action == 'detail-item') {
          this.$router.push({name: 'Detail Voucher', params: { id: data.id}})
        }else if(action == 'download-item'){
          this.$router.push({name: 'Qrcode', params: { id: data.id}})
        }else{
          this.$swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this Voucher!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {

                this.$http.delete(`delete-voucher/` + data.id)
                .then(() => {
                  this.$swal.fire(
                    'Deleted!',
                    'Voucher has been deleted.',
                    'success'
                  ).then(() => {
                    location.reload();
                  })
                }).catch((error) => {
                  if (error.response) {
                    this.errors.code = error.response.status;
                    this.errors.message = error.response.data.meta.message;
                    this.errors.status = error.response.data.meta.code;
                  }
                })
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.$swal.fire(
                'Cancelled',
                'Voucher was successfully canceled for deletion!',
                'error'
              )
            }
          })
        }
      },
      onFilterSet (filterText) {
          this.moreParams = {
              'filter': filterText
          }
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      resetDateFilter () {
        this.startDate = ''
        this.endDate = ''
        this.$events.$emit('date-filter-reset')
      },
      doDateFilter(value){
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.$events.fire('date-set', [this.startDate, this.endDate])
      },
      onDateSet()  {
        this.start_from = this.startDate;
        this.end_to = this.endDate;
        this.moreParams = {
          'start_from': this.start_from,
          'end_to': this.end_to
        }
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onFilterReset () {
          this.moreParams = {}
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onDateFilterReset () {
        this.moreParams = {}
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      }
    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('filter-reset', () => this.onFilterReset())
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
    },
  }
</script>
<style>

.badge--code {
  font-size: 10pt !important;
}
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
